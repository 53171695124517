import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { ModelActions } from '../actions/models/models-exported-actions';
import { UnrealDesiredSceneStateActions } from '../actions/unreal-desired-scene-state/unreal-desired-scene-state-exported-actions';
import { MxeReducers } from '../reducers';

@Injectable()
export class ModelEffects {

    constructor(
        private actions$: Actions,
        private store: Store<MxeReducers>
        ) { }

    ModelState$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(ModelActions.MODELS_LOADED),
                tap(actions => localStorage.setItem('families', JSON.stringify(actions.families)))
            ),
        { dispatch: false }
    );
    
    NewModelSelected$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(ModelActions.NEW_MODEL_SELECTED),
                tap(actions => {
                    localStorage.setItem('currentCarModel', JSON.stringify(actions.currentCarModel));
                    this.store.dispatch(UnrealDesiredSceneStateActions.UPDATE_MODEL_SELECTED({
                        modelCode: actions.currentCarModel.modelCode
                    }))
                }),
            ),
        { dispatch: false }
    );

    ModelOptionsParsed$ = createEffect(() =>
    this.actions$
        .pipe(
            ofType(ModelActions.MODEL_OPTIONS_PARSED),
            tap(actions => localStorage.setItem('packages', JSON.stringify(actions.packages))),
            tap(actions => localStorage.setItem('options', JSON.stringify(actions.options))),
            tap(actions => localStorage.setItem('hiddenOptions', JSON.stringify(actions.hiddenOptions))),
            tap(actions => localStorage.setItem('optionsForAnalytics', actions.optionsForAnalytics)),
            tap(actions => localStorage.setItem('defaultOptions', JSON.stringify(actions.defaultOptions))),
        ),
    { dispatch: false }
    );

    SelectedModelReset$ = createEffect(() =>
    this.actions$
        .pipe(
            ofType(ModelActions.RESET_SELECTED_MODEL),
            tap(() => localStorage.setItem('currentCarModel', '')),
        ),
    { dispatch: false }
    );

    ModelStateReset$ = createEffect(() =>
    this.actions$
        .pipe(
            ofType(ModelActions.RESET_MODELS_STATE),
            tap(() => localStorage.setItem('currentCarModel', '')),
            tap(() => localStorage.setItem('families', '')),
        ),
    { dispatch: false }
    );

    LastModelSelected$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(ModelActions.SET_LAST_MODEL),
                tap(actions => {
                    localStorage.setItem('lastCarModel', JSON.stringify(actions.lastCarModel));
                    localStorage.setItem('lastHiddenOptions', JSON.stringify(actions.lastHiddenOptions))
                    localStorage.setItem('lastOptions', JSON.stringify(actions.lastOptions))
                    localStorage.setItem('lastDefaultOptions', JSON.stringify(actions.lastDefaultOptions))
                    localStorage.setItem('lastPackages', JSON.stringify(actions.lastPackages))
                    localStorage.setItem('lastOptionsForAnalytics', JSON.stringify(actions.lastOptionsForAnalytics))
                }),
                
            ),
        { dispatch: false }
    );
    ResetLastModelSelected$ = createEffect(() =>
    this.actions$
        .pipe(
            ofType(ModelActions.RESET_LAST_MODEL),
            tap(actions => {
                localStorage.setItem('lastCarModel', '');
                localStorage.setItem('lastHiddenOptions', '')
                localStorage.setItem('lastOptions', '')
                localStorage.setItem('lastDefaultOptions', '')
                localStorage.setItem('lastPackages', '')
                localStorage.setItem('lastOptionsForAnalytics', '')
            }),
            
        ),
    { dispatch: false }
);

}