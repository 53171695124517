import { Pack, UIOptItem } from '../../models/car-grid.model'
import { CarModel, Family, ModelsAvailable, ModelsInStock } from '../../models/get-models-service.model'
export const modelsInitialState: ModelsState = {
    families: [],
    availableModels: [],
    currentCarModel: null,
    packages: [],
    options: [],
    hiddenOptions: [],
    optionsForAnalytics: '',
    defaultOptions: [],
    lastCarModel: null,
    lastHiddenOptions: [],
    lastOptions: [],
    lastPackages: [],
    lastDefaultOptions: [],
    lastOptionsForAnalytics: ''

}

export interface ModelsState {
    families: Family[],
    availableModels: ModelsInStock[]
    currentCarModel: CarModel | null,
    packages: Pack[],
    options: UIOptItem[],
    hiddenOptions: UIOptItem[],
    optionsForAnalytics: string,
    defaultOptions: UIOptItem[],
    lastCarModel: CarModel | null,
    lastOptions: UIOptItem[],
    lastHiddenOptions: UIOptItem[]
    lastDefaultOptions: UIOptItem[]
    lastOptionsForAnalytics: string,
    lastPackages: Pack[]

}
