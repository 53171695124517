import { Action, createReducer, on } from '@ngrx/store';
import { ModelActions } from '../actions/models/models-exported-actions';
import { modelsInitialState } from '../initials/models-initial-state';

const _modelReducer = createReducer(modelsInitialState,
    on(ModelActions.MODELS_LOADED, (state, payload) => ({
        ...state,
        families: payload.families
    })),
    on(ModelActions.SET_AVAILABLE_MODELS, (state, payload) => ({
        ...state,
        availableModels: payload.availableModels
    })),
    on(ModelActions.NEW_MODEL_SELECTED, (state, payload) => 
    ({
        ...state,
        currentCarModel: payload.currentCarModel
    })),
    on(ModelActions.SET_LAST_MODEL, (state, payload) => 
    ({
        ...state,
        lastCarModel: payload.lastCarModel,
        lastHiddenOptions: payload.lastHiddenOptions,
        lastOptions: payload.lastOptions,
        lastDefaultOptions: payload.lastDefaultOptions,
        lastPackages: payload.lastPackages,
        lastOptionsForAnalytics: payload.lastOptionsForAnalytics
        
    })),
    on(ModelActions.RESET_LAST_MODEL, (state) => 
    ({
        ...state,
        lastCarModel: null,
        lastHiddenOptions: [],
        lastOptions: [],
        lastDefaultOptions: [],
        lastPackages: [],
        lastOptionsForAnalytics: ''
        
    })),
    on(ModelActions.MODEL_OPTIONS_PARSED, (state, payload) => 
    ({
        ...state,
        packages:  payload.packages,
        options: payload.options,
        hiddenOptions: payload.hiddenOptions,
        optionsForAnalytics: payload.optionsForAnalytics,
        defaultOptions: payload.defaultOptions
    })),
    on(ModelActions.RESET_SELECTED_MODEL, (state) => 
    ({
        ...state,
        currentCarModel: null,
        packages: [],
        options:  [],
        hiddenOptions:  [],
    })),
    on(ModelActions.RESET_MODELS_STATE, (state) => 
    ({
        ...state,
        families: [],
        currentCarModel: null,
        packages: [],
        options:  [],
        hiddenOptions:  [],
    }))
);

export function modelReducer(state = modelsInitialState, action: Action) {
    return _modelReducer(state, action);
}




  
