import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy'
import { MxeReducers } from '../store/reducers';
import { Store } from '@ngrx/store';
import { UserState } from '../store/initials/user-initial-state';
import { distinctUntilChanged, map, Subscription, take, tap } from 'rxjs';
import * as uuid from 'uuid';
import { AppState } from '../store/initials/app-settings-initial-state';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SET_REGION } from '../store/actions/users/user-actions';

@Injectable({
    providedIn: 'root'
  })
  export class SentryMessaging {
    private userState: UserState;
    private userState$: Subscription
    private appState: AppState;
    private appState$: Subscription
    private host$: Subscription
    private SENTRY_SESSION_ID: string;
    private metaEndpoint: string = environment.environment !== 'debug' ? `${environment.homepage_url}/meta/meta/info` : 'https://mxe-dev.maserati.com/meta/meta/info'

    public getSentrySessionId(): string {
        return this.SENTRY_SESSION_ID;
    }
    constructor(private store: Store<MxeReducers>, private http: HttpClient) {
        this.userState$ = this.store.select(s => s.userState).pipe(
            tap(userState => this.userState = userState),
            map(userState => (userState as UserState)?.dealerId),
            distinctUntilChanged()
        ).subscribe((dealerId: string) => {
            if (dealerId) {
                this.SENTRY_SESSION_ID = `session-${uuid.v4()}`
                Sentry.setTags({
                    userSessionId: this.SENTRY_SESSION_ID,
                })
                Sentry.setUser({
                    id: this.userState.dealerId
                })
            }
        })
        this.appState$ = this.store.select(s => s.appState).subscribe(
            (appState: AppState) => this.appState = appState
        )

        this.host$ = this.store.select(s => s.appState.host).pipe(take(1))
        .subscribe((host: string) => {
            if(host == 'localhost:8080') {
                console.warn('[Meta] region set to localhost')
                Sentry.setTag('region', 'localhost')
                this.store.dispatch(SET_REGION({region: 'localhost'}))
            } else {
                if(!host.includes('local')) {
                    //CLOUD VERSION
                    this.http.get<any>(this.metaEndpoint).subscribe({
                        next: metadata => {
                            if (metadata && metadata['region']) {
                                console.log('[Meta] region of interest: ', metadata['region']);
                                Sentry.setTag('region', metadata['region']);
                                this.store.dispatch(SET_REGION({ region: metadata['region'] }));
                            }
                        },
                        error: err => {
                            console.error('[Meta] Error occurred: ', err);
                            Sentry.setTag('region', 'undefined');
                            this.store.dispatch(SET_REGION({ region: 'undefined' }));
                        }
                    });
                    
                } else {
                    //ONPREMISE VERSION
                    this.store.select(s => s.userState.region).subscribe(
                        region => {
                            console.log('[Meta] region of interest: ', region)
                            Sentry.setTag('region', region)
                        }
                    )
                }
            }
        })
    }


    public logEvent(message: string, severity: Sentry.SeverityLevel, extra: any): string {
        return Sentry.captureEvent({
            message: message,
            level: severity,
            user: {
                dealerCode: this.userState.dealerId,
                dealersInfos: this.userState.dealersInfo,
                applicationStep: this.appState.applicationStep,
                countryCode: this.appState.countryCode,
                languageId: this.appState.languageId
            },
            extra: extra
        })
    }
  }